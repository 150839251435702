(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['./codebase'], factory(Codebase));
    } else if (typeof exports === 'object') {
        // CommonJS
        module.exports = factory(require('./codebase'));
    } else {
        // Browser globals (Note: root is window)
        root.returnExports = factory(root.Codebase);
    }
}(this, function (Codebase) {
// Initialize when page loads
    jQuery(function () {
        let body = $('body');
        let modal_history = [];
        let datepickers = []
        // init Codebase
        Codebase.init();

        // init masked-inputs
        Codebase.helper('masked-inputs');

        $('[data-tooltip="tooltip"]').tooltip();

        let initDatepicker = function () {
            let i = 0
            // Init datepicker (with .js-datepicker and .input-daterange class)
            jQuery('.js-datepicker:not(.js-datepicker-enabled)').add('.input-daterange:not(.js-datepicker-enabled)').each(function () {
                let el = jQuery(this);
                datepickers.push({show: false})
                // Add .js-datepicker-enabled class to tag it as activated
                el.addClass('js-datepicker-enabled');
                // Init
                el.datepicker({
                    weekStart: 0,
                    autoclose: true,
                    todayHighlight: true,
                    calendarWeeks: true,
                    format: 'yyyy-mm-dd',
                    language: 'sv',
                    orientation: 'bottom' // Position issue when using BS4, set it to bottom until officially supported
                }).on('show', function (e) {
                    if (!datepickers[i].show) {
                        findBigHolidaysOrRedDays(e.timeStamp)
                    }
                    datepickers[i].show = true
                }).on('changeMonth', function (e) {
                    findBigHolidaysOrRedDays(e.date.getTime())
                }).on('hide', function (e) {
                    datepickers[i].show = false
                });

                if (el.is('input'))
                    el.attr('autocomplete', 'off');
                else
                    el.find('input').attr('autocomplete', 'off');
            });
        };

        initDatepicker();

        let findBigHolidaysOrRedDays = function (timeStamp) {
            const date = new Date(timeStamp)
            let month = date.getMonth() + 1
            const year = date.getFullYear()
            if (month < 10) {
                month = '0' + month
            }
            $.ajax({
                method: 'POST',
                url: window.findBigHolidaysOrRedDaysUrl,
                data: {
                    month: month,
                    year: year,
                },
                success: function (data) {
                    $('.datepicker .day').each(function (i, obj) {
                        var d = new Date(parseInt(obj.dataset.date))
                        var year = d.getFullYear()
                        var month = d.getUTCMonth() + 1
                        if (month < 10) {
                            month = '0' + month
                        }
                        var day = d.getUTCDate()
                        if (day < 10) {
                            day = '0' + day
                        }
                        obj.dataset.prettyDate = year + '-' + month + '-' + day
                    })
                    for (var key in data) {
                        if (data.hasOwnProperty(key)) {
                            $('.datepicker .day[data-pretty-date="' + data[key].date + '"]').addClass('special')
                        }
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        let initSelect2 = function () {
            // init select2 for modals
            jQuery('.modal .js-select2:not(.js-select2-enabled)').each(function () {
                let el = jQuery(this);
                let modal = jQuery(this).closest('.modal');

                // Add .js-select2-enabled class to tag it as activated
                el.addClass('js-select2-enabled');

                // Init
                el.select2({
                    dropdownParent: modal
                });
            });
        };

        initSelect2();

        body.on('click', '.js-select2-all', function () {
            let select2 = $(this).data('select');
            if ($(this).is(':checked')) {
                $(select2 + " > option").prop("selected", "selected");
                $(select2).trigger("change");
            } else {
                $(select2).val('');
                $(select2).trigger("change");
            }
        });

        let initTimepicker = function () {
            let el = $('.timepicker');

            el.timepicker({
                timeFormat: 'HH:mm',
                interval: 30,
                minTime: '00:00',
                maxTime: '23:59',
                //defaultTime: '10',
                startTime: '00:00',
                dynamic: false,
                dropdown: true,
                scrollbar: true,
                change: function (time) {
                    // the input field
                    let element = $(this), text;
                    element.trigger('timepicker_change');
                }
            });
            el.attr('autocomplete', 'off');
        };

        initTimepicker();

        let initValidation = function () {
            jQuery('form').not('#logout-form').validate({ // no need to validate logout form =D
                ignore: ":hidden:not(.js-validate-required)",
                errorClass: 'invalid-feedback animated fadeInDown',
                errorElement: 'div',
                errorPlacement: function (error, e) {
                    jQuery(e).closest('.form-group').append(error);
                },
                highlight: function (e) {
                    jQuery(e).closest('.form-group').removeClass('is-invalid').addClass('is-invalid');
                },
                success: function (e) {
                    jQuery(e).closest('.form-group').removeClass('is-invalid');
                    jQuery(e).remove();
                },
                submitHandler: function (form) { // <- pass 'form' argument in
                    $("[type='submit']").attr("disabled", "disabled");
                    this.submit(); // <- use 'form' argument here.
                }
            });

            /*
             * Translated default messages for the jQuery validation plugin.
             * Locale: SV (Swedish; Svenska)
             */
            $.extend($.validator.messages, {
                required: "Detta f&auml;lt &auml;r obligatoriskt.",
                remote: "Var snäll och åtgärda detta fält.",
                maxlength: $.validator.format("Du f&aring;r ange h&ouml;gst {0} tecken."),
                minlength: $.validator.format("Du m&aring;ste ange minst {0} tecken."),
                rangelength: $.validator.format("Ange minst {0} och max {1} tecken."),
                email: "Ange en korrekt e-postadress.",
                url: "Ange en korrekt URL.",
                date: "Ange ett korrekt datum.",
                dateISO: "Ange ett korrekt datum (&Aring;&Aring;&Aring;&Aring;-MM-DD).",
                number: "Ange ett korrekt nummer.",
                digits: "Ange endast siffror.",
                equalTo: "Ange samma v&auml;rde igen.",
                range: $.validator.format("Ange ett v&auml;rde mellan {0} och {1}."),
                max: $.validator.format("Ange ett v&auml;rde som &auml;r mindre eller lika med {0}."),
                min: $.validator.format("Ange ett v&auml;rde som &auml;r st&ouml;rre eller lika med {0}."),
                creditcard: "Ange ett korrekt kreditkortsnummer."
            });
        };

        initValidation();

        // init select2 for the rest
        Codebase.helper('select2');

        // disable click on links with href=#
        body.on("click", '[href="#"]', function (event) {
            event.preventDefault()
        });

        // init notifications
        var notification = $('.notification');
        var popupId = notification.data('uniqid');

        if (sessionStorage && notification.length) {
            // prevent from showing if it exists in a storage (shown);
            if (!sessionStorage.getItem('shown-' + popupId)) {
                $.notify({
                    // options
                    icon: notification.data('icon'),
                    message: notification.data('message')
                }, {
                    // settings
                    type: notification.data('type'),
                    placement: {
                        from: 'bottom',
                        align: 'left'
                    },
                    element: 'body',
                    allow_dismiss: true,
                    newest_on_top: true,
                    showProgressbar: false,
                    offset: 20,
                    spacing: 10,
                    z_index: 1033,
                    delay: 5000,
                    timer: 1000,
                    template: '<div data-notify="container" class="col-11 col-sm-3 alert alert-{0}" role="alert">' +
                        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                        '<span data-notify="icon"></span> ' +
                        '<span data-notify="title">{1}</span> ' +
                        '<span data-notify="message">{2}</span>' +
                        '<div class="progress" data-notify="progressbar">' +
                        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                        '</div>' +
                        '<a href="{3}" target="{4}" data-notify="url"></a>' +
                        '</div>'
                });
            }
            sessionStorage.setItem('shown-' + popupId, '1');
        }

        // init alert on delete
        swal.setDefaults({
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-lg btn-alt-success m-5',
            cancelButtonClass: 'btn btn-lg btn-alt-danger m-5',
            inputClass: 'form-control'
        });

        body.on('click', '.js-swal-destroy', function () {
            var button = $(this);
            swal({
                title: 'Är du säker?',
                text: 'Du är på väg att radera ' + button.data('name'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d26a5c',
                confirmButtonText: 'Ja, radera',
                cancelButtonText: 'Avbryt',
                html: false
            }).then((result) => {
                if (result.value) {
                    var form = $('<form></form>');
                    form.attr("method", "post");
                    form.attr("action", button.data('path'));
                    form.attr("accept-charset", "UTF-8");

                    $.each({
                        '_method': 'DELETE',
                        '_token': $('meta[name="csrf-token"]').attr('content')
                    }, function (key, value) {
                        var field = $('<input>');

                        field.attr("type", "hidden");
                        field.attr("name", key);
                        field.attr("value", value);

                        form.append(field);
                    });
                    $(form).appendTo('body').submit();
                }
            });
        });

        body.on('click', '.js-swal-confirm', function () {
            var button = $(this);
            swal({
                title: 'Är du säker?',
                text: 'Du är på väg att ' + button.data('full_action'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d26a5c',
                confirmButtonText: 'Ja, ' + button.data('action'),
                cancelButtonText: 'Avbryt',
                html: false
            }).then((result) => {
                if (result.value) {
                    window.location = button.data('remote');
                }
            });
        });


        /*
         * Misc functions used on creating and updating timesheets
         */
        var calculateTime = function () {
            var employee_id = $('#employee_id').val();

            /*
             * Decide if overtime should be calculated automatically
             */
            var auto = $('#create-timesheet').data('auto_overtime');
            if ($('#create-timesheet').is('[data-employees]')) {
                var employees = $('#create-timesheet').data('employees');

                if (employees[employee_id])
                    auto = 0;
            }

            if ($('#overtime').data('override'))
                auto = 0;

            if (auto)
                $('#overtime').attr('readonly', true);
            else
                $('#overtime').removeAttr('readonly');

            /*
             * Decide if flex field should be displayed
             */
            var flex = 0;
            if ($('#create-timesheet').is('[data-flex]')) {
                var employees_flex = $('#create-timesheet').data('flex');
                flex = employees_flex[employee_id];
            }

            if (flex)
                $('#change_flex').show();
            else
                $('#change_flex').hide();

            var date = $('#booking_date').text();
            var start = new Date(date);
            var start_time = $('#start_time').val().split(':');
            start.setHours(start_time[0], start_time[1]);

            var end = new Date(date);
            var end_time = $('#end_time').val().split(':');
            end.setHours(end_time[0], end_time[1]);
            if (end < start)
                end.setDate(end.getDate() + 1);

            var real_time = (end.getTime() - start.getTime()) / 60000;

            var real_break = 0;

            if ($('#break_start').val() !== '' && $('#break_end').val() !== '') {
                var break_start = new Date(date);
                var break_start_time = $('#break_start').val().split(':');
                break_start.setHours(break_start_time[0], break_start_time[1]);

                var break_end = new Date(date);
                var break_end_time = $('#break_end').val().split(':');
                break_end.setHours(break_end_time[0], break_end_time[1]);
                if (break_end < break_start)
                    break_end.setDate(break_end.getDate() + 1);

                real_break = (break_end.getTime() - break_start.getTime()) / 60000;

                // Make sure break is within reported hours
                let break_within_hours = false;
                console.log(break_start >= start);
                console.log(start);
                console.log(break_end <= end);
                console.log(end);
                if ((break_start >= start) && (break_end <= end)) {
                    console.log('OK!');
                    break_within_hours = true;
                } else {
                    break_start.setDate(break_start.getDate() + 1);
                    break_end.setDate(break_end.getDate() + 1);
                    if ((break_start >= start) && (break_end <= end)) {
                        break_within_hours = true;
                        console.log('OK after adding a day!');
                    }
                }

                let break_outside_hours_check = $('#break_outside_hours');
                let break_outside_hours_note = $('#break_outside_hours_note');
                if (!break_within_hours) {
                    break_outside_hours_check.removeAttr('checked');
                    break_outside_hours_note.removeClass('d-none');
                } else {
                    break_outside_hours_check.attr('checked', 'checked');
                    break_outside_hours_note.addClass('d-none');
                }

                console.log(break_within_hours);
            }

            let overtime = (real_time - real_break) - $('#js-booked_time').text();
            if (overtime < 0)
                overtime = 0;

            // Make sure 60 min break is reported on timesheets over 8 h
            let break_checkbox = $('#required_break');
            let break_notification = $('#required_break_note');
            if (real_time >= (8 * 60) && real_break < 60) {
                break_checkbox.removeAttr('checked');
                break_notification.removeClass('d-none');
            } else {
                break_checkbox.attr('checked', 'checked');
                break_notification.addClass('d-none');
            }


            $('#js-real_time').text(real_time - real_break);
            $('#js-real_break').text(real_break);
            $('#js-overtime').text(overtime);
            if (auto) {
                $('#overtime').val(overtime);
            }

            if ($('#overtime').val() > 0) {
                $('#overtime_reason').attr('required', true);
            } else {
                $('#overtime_reason').removeAttr('required');
            }
        };

        let loadTime = function () {
            let breaks;

            if ($('#break_start').val() && $('#break_end').val())
                breaks = '/' + $('#break_start').val() + '/' + $('#break_end').val();
            else
                breaks = '';
            let id = $('#js_time').data('booking');
            let remote = $('#js_time').data('remote');
            $('#js_time').load(remote + '/' + id + '/' + $('#start_time').val() + '/' + $('#end_time').val() + breaks, function () {

            });
        };


        $('#create-timesheet').each(function () {
            calculateTime();
            loadTime();
        });

        body.on('focusout timepicker_change change', '.js-calculate-time', function () {
            calculateTime();
        });

        body.on('timepicker_change', '.js-calculate-time', function () {
            loadTime();
        });

        body.on('click', '#js-edit-overtime', function () {
            $('#overtime').data('override', true);
            $('#js-edit-overtime').addClass('d-none');
            $('#js-edit-overtime-notice').removeClass('d-none');
            calculateTime();
        });


        var loadReportDefaults = function () {
            var id = $('#js_load_report_defaults').val();
            $('#booking_reports').closest('.block').addClass('block-mode-loading');
            $('#booking_reports').load($('#js_load_report_defaults').data('remote') + id, function () {
                $('#booking_reports').closest('.block').removeClass('block-mode-loading');
            });
        };

        body.on('select2:select', '#js_load_report_defaults', function () {
            loadReportDefaults();
        });

        body.on('click', '.js-range-set', function () {
            $('#js-range-start').datepicker('setDate', $(this).data('start'));
            $('#js-range-end').datepicker('setDate', $(this).data('end'));
        });

        /**
         * Count characters in input
         */
        body.on('keyup', '.js-count-chars', function () {
            console.log($(this).val().length);
            $('.count').html($(this).val().length);
        });

        $(".js-period-navigate").click(function () {
            let url = $(this).data('remote');
            let arg1 = $($(this).data('remote-arg1')).val();
            let arg2 = $($(this).data('remote-arg2')).val();
            if (arg1 !== '' && arg2 !== '')
                window.location = url + arg1 + '/' + arg2;
        });

        body.on('click', '[data-toggle="modal"][data-remote], [data-toggle="content"][data-remote], [data-toggle="back"][data-remote]', function () {
            if ($(this).data('toggle') === 'back') {
                modal_history.pop();
            } else {
                modal_history.push($(this).data("remote"));
            }

            $('[data-tooltip="tooltip"]').tooltip('hide');
            $($(this).data("target") + ' .modal-content').html('<div class="block block-themed block-transparent block-mode-loading mb-0"><div class="block-header bg-primary-dark"><h3 class="block-title">Laddar...</h3></div><div class="block-content"><p>Laddar...</p></div></div>');
            $($(this).data("target") + ' .modal-content').load($(this).data("remote"), function (responseText, textStatus, XMLHttpRequest) {
                console.log(XMLHttpRequest)
                if (XMLHttpRequest.status == "403") {
                    $(this).html('<div class="block block-themed block-transparent mb-0">' +
                        '<div class="block-header bg-primary-dark">' +
                        '<h3 class="block-title">Fel - ingen åtkomst</h3>' +
                        '</div>' +
                        '<div class="block-content">' +
                        '<h4>Du har inte tillåtelse att komma åt den här funktionen.</h4>' +
                        '</div></div>')
                } else {
                    initSelect2();
                    initTimepicker();
                    initDatepicker();
                    initValidation();

                    $('#create-timesheet').each(function () {
                        calculateTime();
                        loadTime();
                    });

                    $('.block.toggleable').on('click', function () {
                        Codebase.blocks('#' + $(this).attr('id'), 'content_toggle')
                    });

                    $('#is_series').on('click', function () {
                        $('div.series').toggleClass('d-none');
                    });

                    $('#is_series_leave').on('click', function () {
                        $('div').toggleClass('d-none');
                    });
                    
                    if (modal_history.length > 1) {
                        $('.modal-footer').prepend('<button type="button" class="btn btn-sm btn-alt-secondary" data-toggle="back" data-target="#modal-popout" data-remote="' + modal_history[modal_history.length - 2] + '"><i class="si icon-arrow-left"></i></button>');
                    }

                    $('[data-tooltip="tooltip"]').tooltip();
                }
            });
        });

        body.on('show.bs.modal', '#modal-popout', function () {
            $('[data-tooltip="tooltip"]').tooltip('hide');
        });

        body.on('hidden.bs.modal', '#modal-popout', function () {
            modal_history = [];
        });
    });
    /*	// Methods
        function notHelloOrGoodbye(){}; // A private method
        function hello(){}; // A public method because it's returned (see below)
        function goodbye(){}; // A public method because it's returned (see below)

        // Exposed public methods
        return {
            init: function () {
                console.log('Test');
            }
        };
        */
}));

