''// Core
window.$ = window.jQuery = require('jquery');
require('./core/bootstrap.bundle.min');
require('./core/jquery.slimscroll.min');
require('./core/jquery.scrollLock.min');
require('./core/jquery.appear.min');
require('./core/jquery.countTo.min');
require('./core/js.cookie.min');
window.Codebase = require('./codebase');

// Plugins
window.URI = require('urijs');
require('./plugins/masked-inputs/jquery.maskedinput.min');
require('./plugins/jquery-validation/jquery.validate.min');
require('./plugins/jquery-validation/additional-methods.min');
require('./plugins/select2/select2.full.min');
require('./plugins/bootstrap-notify/bootstrap-notify.min');
require('./plugins/select2/select2.js');
require('./plugins/bootstrap-datepicker/js/bootstrap-datepicker.js');
require('./plugins/bootstrap-datepicker/locales/bootstrap-datepicker.sv.min');

//require('./plugins/sweetalert2/es6-promise.auto.min');
window.timepicker = require('./plugins/jquery-timepicker/jquery.timepicker.min');
window.swal = require('./plugins/sweetalert2/sweetalert2.min');
window.Chart = require('./plugins/chartjs/Chart.bundle.min.js');
window.moment = require('moment')
// Custom js
require('./custom');

window._ = require('lodash');
